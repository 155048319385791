<template>
  <section>
    <nav v-if="!$route.params.id" class="tabs-navbar" :class="{ dark: false }">
      <ul class="tabs-navbar-list">
        <li
          v-for="(item, index) in navbar"
          v-can="item.permission"
          :key="index"
          class="tabs-navbar-list__item"
        >
          <router-link
            class="tabs-navbar-list__item-link"
            :to="{ name: item.link }"
          >
            {{ $t(item.name) }}
          </router-link>
        </li>
      </ul>
    </nav>
    <router-view></router-view>
  </section>
</template>

<script>
export default {
  data() {
    return {
      navbar: [
        {
          link: "doctor-cabinet.informal-patients",
          name: "message.my_informal_patients",
          permission: "doctor_cabinet.patients",
        },
        {
          link: "doctor-cabinet.patients",
          name: "message.my_patients",
          permission: "doctor_cabinet.patients",
        },
        {
          link: "doctor-cabinet.AllPatients",
          name: "message.my_conclusions",
          permission: "doctor_cabinet.AllPatients",
        },
        {
          link: "doctor-cabinet.outsource",
          name: "message.outsource",
          permission: "doctor_cabinet.outsource",
        },
        {
          link: "doctor-cabinet.outsource-party",
          name: "message.outsource_party",
          permission: "doctor_cabinet.outsource_party",
        }
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>